/**
 *  Copyright (C) 2022 - Jeci SARL - https://jeci.fr
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU Affero General Public License as
 *  published by the Free Software Foundation, either version 3 of the
 *  License, or (at your option) any later version.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU Affero General Public License for more details.
 *
 *  You should have received a copy of the GNU Affero General Public License
 *  along with this program.  If not, see https://www.gnu.org/licenses/agpl-3.0.html.
 */
import { i18n } from "./TranslationService.js";
import { useConfigStore } from "@pristy/pristy-libvue";

const $t = i18n.global.t;
class MenuService {
  computeMenu() {
    const config = useConfigStore();

    let menu = [
      {
        label: $t("menu.portail"),
        key: "menuPortail",
        to: { name: "welcome" },
        class: "layout-menuitem-root-text",
        items: [
          {
            label: $t("menu.profile"),
            class: "menu-item responsive-text",
            to: { name: "profile" },
          },
          {
            label: $t("menu.trashcan"),
            class: "menu-item responsive-text",
            icon: "pi pi-trashcan",
            to: { name: "corbeille" },
          },
          {
            label: $t("menu.requests"),
            class: "menu-item responsive-text",
            to: { name: "requests" },
          },
        ],
      },
      {
        label: $t("menu.admin"),
        key: "adminPortail",
        class: "layout-menuitem-root-text",
        items: [
          {
            label: $t("menu.userManagement"),
            class: "menu-item responsive-text",
            to: { name: "manage-users" },
          },
          {
            label: $t("menu.billing"),
            class: "menu-item responsive-text",
          },
          {
            label: $t("menu.stats"),
            class: "menu-item responsive-text",
          },
        ],
      },
    ];
    if (config.MENU_ESPACES) {
      menu.push({
        label: $t("menu.espaces"),
        key: "menuEspaces",
        url: `${config.ESPACES_HOST}/espaces/mes-espaces`,
        class: "layout-menuitem-root-text",
      });
    }
    if (config.MENU_ACTES) {
      menu.push({
        label: $t("menu.actes"),
        key: "menuActes",
        url: `${config.ACTES_HOST}/actes`,
        class: "layout-menuitem-root-text",
      });
    }
    if (config.MENU_MARCHES) {
      menu.push({
        label: $t("menu.marches"),
        key: "menuMarches",
        url: `${config.MARCHES_HOST}/marches`,
        class: "layout-menuitem-root-text",
      });
    }
    return menu;
  }
}

export default new MenuService();
